import React, { useState , useEffect, useCallback , useRef} from 'react'
import { useSelector , useDispatch , Provider} from 'react-redux';
import { FormattedMessage , useIntl } from "react-intl";
import moment from "moment";
import Page from '../../Component/All/Page';
import SearchBar from './searchBar'
import Datep from '../../Component/All/Date'
import Spinner from '../Spinner/Spinner';
import GroupsIcon from '@mui/icons-material/Groups';
import ReplyIcon from '@mui/icons-material/Reply';
import Swal from 'sweetalert2'

var currentDate = new Date();
currentDate.setDate(currentDate.getDate());
currentDate.setHours(0, 0, 0, 0);

var endToDate = new Date();
endToDate.setHours(23, 59, 59, 999);

const gameTpye = [
    {
        name:'全部',
        value : 0
    },
    {
        name:'彩票',
        value : 1
    },
    {
        name:'電子',
        value : 2
    },
]

const AgentsProfit = ({setBoxSwitch}) => {
  const intl = useIntl();

  const [perpage] = useState(10)
  const [nowPage, setNowPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [platformMemberID , setPlatformMemberID] = useState('')
  const [loading , setLoading] = useState(true)
  const [gameSel , setGameSel] = useState('0')
  //redux
  const dispatch = useDispatch();
  const agentPl = useSelector(state => state.agent.agentPl)


  useEffect(() => {
  dispatch({ type: 'SAGA_FETCH_AGENTPL_API' , platformMemberID , setLoading , setTotalPage , perpage , nowPage});
  }, [dispatch , platformMemberID , nowPage]);

//   useEffect(() => {
//     setTotalPage(Math.ceil(betHistory.data.data.pagination.total / perpage))
//   }, [betHistory.data.status])

  const [firstLoading , setFirstLoading] = useState(true)
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(endToDate);
  const startRef = useRef()
  const endRef = useRef()
  const searchHandler = ( nowPage = 1 , perPage = 15) => {
    // event.preventDefault();
    //第一次loading讀取當天列表
    if(firstLoading){
        const startStamp = 0;
        const endStamp = 0;
        dispatch({ type: 'SAGA_FETCH_AGENTPL_API' , platformMemberID , setLoading , setTotalPage , perpage , nowPage , startStamp , endStamp});
        setFirstLoading(false)
    } else{
        const encodedStartTime = moment(startRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
        const encodedEndTime = moment(endRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
        const startStamp = parseInt(`${moment(encodedStartTime, 'YYYY-MM-DD HH:mm:ss').unix()}000`);
        const endStamp = parseInt(`${moment(encodedEndTime, 'YYYY-MM-DD HH:mm:ss').unix()}999`);
        dispatch({ type: 'SAGA_FETCH_AGENTPL_API' , platformMemberID , setLoading , setTotalPage , perpage , nowPage , startStamp , endStamp});
        
    }
  };

  useEffect(() => {
    searchHandler(nowPage,perpage)
  }, [nowPage,perpage,platformMemberID]);
  
  return (
    <div className="agentsWrap agentsProfitWrap bottWrap">
            <div className='listBx'>
                <div className='titleFlex'>
                    <button className='backBtn' onClick={()=>{
                        setBoxSwitch(false)
                    }}
                    ><ReplyIcon/></button>
                    <div>
                        <FormattedMessage
                            id={`profile.agentProfit`}
                        ></FormattedMessage>
                    </div>
                </div>
                {/* <div className='gameselBx'>
                    <ul>
                        {
                            gameTpye.map((v)=>{
                                return(
                                    <li key={v.value} onClick={()=>setGameSel(`${v.value}`)}
                                    data-active={gameSel.includes(`${v.value}`)}
                                    >
                                        {v.name}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div> */}
                <SearchBar setPlatformMemberID={setPlatformMemberID}/>
                <Datep
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                startRef={startRef}
                endRef={endRef}
                />
                <button className='searchBtn' 
                    onClick={() => {
                            if (startDate && endDate) {
                                searchHandler(1,15);
                                setNowPage(1);
                            } else {
                                Swal.fire({
                                    icon: "warning",
                                    text:intl.formatMessage({
                                        id: 'alert.notSelected',
                                    }),
                                    showConfirmButton: true,
                                });
                            }
                        }}>
                        <FormattedMessage
                            id={`profile.inquire`}
                        ></FormattedMessage>
                </button>
                {agentPl.status ? 
                    agentPl.data.response.length === 0  ?
                    <div className='notData'>
                        <FormattedMessage
                            id={`profile.noDataFound`}
                        ></FormattedMessage>
                    </div> :
                    <>
                        {
                            loading ||
                            <>
                                
                                <div className='selBx'>
                                    {
                                        agentPl.data.direct_group.length === 0 ?
                                        <div>{agentPl.data.response[0].account}</div>
                                        :
                                        agentPl.data.direct_group.map((v)=>{
                                            return(
                                                <div key={v.id} onClick={()=>{
                                                    setPlatformMemberID(v.account)
                                                }}>{v.account}</div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='scrollBx'>
                                    {  
                                        <ul>
                                            {
                                                // console.log(agentPl)
                                                agentPl.data.response.map((v)=>{ 
                                                    return (
                                                        <li key={v.id}>
                                                            <div>
                                                                <span><GroupsIcon/></span>
                                                                <i onClick={()=>{
                                                                    setPlatformMemberID(v.account)
                                                                }}>{v.account}</i>
                                                            </div>
                                                            {/* <div>
                                                                <span>
                                                                    <FormattedMessage
                                                                        id={`agent.betAmount`}
                                                                    ></FormattedMessage>
                                                                </span>
                                                                {v.bet_amount}
                                                            </div>
                                                            <div>
                                                                <span>
                                                                    <FormattedMessage
                                                                        id={`agent.deposit`}
                                                                    ></FormattedMessage>
                                                                </span>
                                                                {v.deposit}
                                                            </div> */}
                                                            {/* <div>
                                                                <span> 
                                                                    <FormattedMessage
                                                                        id={`agent.withdrawMoney`}
                                                                    ></FormattedMessage>
                                                                </span>
                                                                {v.withdraw}
                                                            </div>
                                                            <div>
                                                                <span>
                                                                    <FormattedMessage
                                                                            id={`agent.gameWinningOrLosing`}
                                                                    ></FormattedMessage>
                                                                </span>
                                                                {v.win}
                                                            </div> */}
                                                            <div>
                                                                <span>
                                                                    <FormattedMessage
                                                                            id={`agent.rebate`}
                                                                    ></FormattedMessage>
                                                                </span>
                                                                {v.dividend}
                                                            </div>
                                                            {/* <div>
                                                                <span>
                                                                    <FormattedMessage
                                                                            id={`agent.profitAndLoss`}
                                                                    ></FormattedMessage>
                                                                </span>
                                                                {v.profit}
                                                            </div> */}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </div>
                            </>
                        }
                    </>
                    : 
                    <Spinner/> 
                }

                 {totalPage > 1 &&
                <Page
                    totalPage={totalPage}
                    setNowPage={setNowPage}
                    nowPage={nowPage}
                />}
                
            </div>
    </div>
  )
}

export default AgentsProfit