import React, { useRef, useState , useEffect , useContext } from 'react'
// import { ErrorContext } from '../../App';

import './LoginForm.scss';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { FormattedMessage , useIntl } from "react-intl";
// import { FaUser, FaLock } from 'react-icons/fa';
import { RiLockPasswordFill } from 'react-icons/ri';
import { HiOutlineClipboardCheck } from 'react-icons/hi';

import { FaUser } from 'react-icons/fa';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

import { FaBeer } from 'react-icons/fa';
import { Link } from "react-router-dom";
import api , { NowplatformCode , NowpBaseURL } from '../../api/api';
import Swal from 'sweetalert2'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { changLan} from '../../App';

import Icon1 from '../../images/login/user.svg'
import Icon2 from '../../images/login/lock.svg'
import Icon3 from '../../images/login/shield-check.svg'

import { ErrorContext } from '../../Routes';



const now = new Date();
const currentHour = now.getHours() < 10 ? `0${now.getHours()}` : `${now.getHours()}`;
const currentMinutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : `${now.getMinutes()}`;

const LoginForm = ({lang , setLang}) => {
  const { ErrorMessage } = useContext(ErrorContext);
  const navigate = useNavigate();
  const intl = useIntl();
  const [userName , setUserName] = useState('');
  const [password , setPassword] = useState('');
  const [csPhone , setcsPhone] = useState('');
  const [ platformCode , setPlatformCode] = useState('');
  const [csLoading , setCsLoading] = useState(true)
  // 用ref 紀錄 input value
  // const usernameRef = useRef()
  // const passwordRef = useRef()
  // const platformCodeRef = useRef()
  // URL
  const Login_URL = `/login`;
  const CS_URL = `/cs?language=${localStorage.getItem('language')}`;
  const PF_URL = `/pf-setting`;
  const ariaLabel = { 'aria-label': 'description' };

  // const loginInputs = [
  //   {
  //     name: "用戶名",
  //     require: true,
  //     type: "text",
  //     valueRef: usernameRef,
  //     icon: <FaUser/>,
  //   },
  //   {
  //     name: "密碼",
  //     require: true,
  //     type: "password",
  //     valueRef: passwordRef,
  //     icon: <RiLockPasswordFill />
  //   },
  //   {
  //     name: "站台代碼",
  //     require: true,
  //     type: "text",
  //     valueRef: platformCodeRef,
  //     icon: null
  //   }
  // ]
  
  useEffect(()=>{
    getCSphone()
    localStorage.setItem("language", 'cn');
  },[])

  useEffect(()=>{
    if(localStorage.getItem("access_token")){
      Swal.fire({
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
      });
      setTimeout(() => {
        navigate(`/index`);
      }, 1000);
    }
  },[])

  const atLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await api.post(Login_URL, JSON.stringify({
        account: userName,
        password: password,
      }), {
        headers: {
          // dm168
          "x-platform-code": NowplatformCode,
          "Content-Type": "application/json",
          
        },
      });
      if (res.data.status) {
        localStorage.setItem("access_token", res.data.data.access_token);
        localStorage.setItem("x-platform-code", NowplatformCode);
        localStorage.setItem("baseURL", NowpBaseURL);
        Swal.fire({
          icon: "success",
          // text: "登入成功",
          showConfirmButton: false,
          timer: 1000,
        });
        getPfSetting()
        setTimeout(() => {
          navigate(`/index`);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
      // return <ErrorMessage code={code} msg={msg}/>
    }
  };
  // 拿客服電話
  const getCSphone = async () => {
    setCsLoading(true)
    try {
      const res = await api.get(CS_URL, {
        headers: {
          "x-platform-code": NowplatformCode,
          "Content-Type": "application/json",
        },
      });
      if (res.data.status) {
        setcsPhone(res.data.data)
        setCsLoading(false)
      }
    } catch (err) {
      console.log(err);
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg , 'cs')
    }
  };
  // 拿站台設定
  const getPfSetting = async () => {
    setCsLoading(true)
    try {
      const res = await api.get(PF_URL, {
        headers: {
          "x-platform-code": NowplatformCode,
          "Content-Type": "application/json",
        },
      });
      if (res.data.status) {
        localStorage.setItem("country_code", res.data.data.country_code);
        localStorage.setItem("currency", res.data.data.currency);
        localStorage.setItem("logo_image_link", res.data.data.logo_image_link);
        localStorage.setItem("logo_link", res.data.data.logo_link);
      }
    } catch (err) {
      console.log(err);
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg , 'cs')
    }
  };



  const goCS = (index) =>{
    window.location = `${index}`
  }


  const handleButtonClick = (e) => {
    e.preventDefault();
    let user_captcha = document.getElementById('user_captcha_input').value;

    if (validateCaptcha(user_captcha)===true) {
      // 驗證成功
        loadCaptchaEnginge(4,'white','black','numbers');
        document.getElementById('user_captcha_input').value = "";
        atLogin(e)
    }
    else {
      // 驗證碼錯誤
        Swal.fire({
          icon: "warning",
          text: intl.formatMessage({
            id: 'alert.verifError',
          }),
          showConfirmButton: false,
          timer: 1000,
        });
        document.getElementById('user_captcha_input').value = "";
    }
  
  };

  useEffect(() => {
    loadCaptchaEnginge(4,'white','black','numbers');
  }, []);

  return (
    <div className="loginWrap">
      <div className='logo'></div>
      <form action="" onSubmit={handleButtonClick}>
        <h3>
          <FormattedMessage
              id={`login.login`}
          ></FormattedMessage> 
        </h3>
        
        <div className="loginInput mb-2">
          <Input 
              sx={{'width' : '100%'}} 
              // placeholder='用戶名'
              placeholder={intl.formatMessage({
                id: 'login.username',
              })}
              type='text'
              required
              inputProps={ariaLabel} 
              onChange={(e) =>
                setUserName(e.target.value)}
          /><img src={Icon1} className='icon' />
        </div>
        <div className="loginInput mb-2">
          <Input 
              sx={{'width' : '100%'}} 
              placeholder={intl.formatMessage({
                id: 'login.password',
              })}
              type='password'
              required
              inputProps={ariaLabel} 
              onChange={(e) =>
                setPassword(e.target.value)}
          /><img src={Icon2} className='icon' />
        </div>
        <div className="loginInput mb-3">
          <input
            type="text"
            required
            // placeholder={intl.formatMessage({
            //   id: 'login.password',
            // })} 
            id="user_captcha_input" name="user_captcha_input"
            placeholder={intl.formatMessage({
              id: 'profile.verificationCode',
            })}
            />
          <img src={Icon3} className='icon' />
        </div>
        <div className='captchaBx'>
          <LoadCanvasTemplate reloadText={intl.formatMessage({
              id: 'trader.manualRefresh',
            })} reloadColor="#A0CFFF"/>
        </div>
        {/* <div className="loginInput mb-2">
          <Input 
              sx={{'width' : '100%'}} 
              placeholder={intl.formatMessage({
                id: 'login.sitecode',
              })}
              type='text'
              required
              inputProps={ariaLabel} 
              onChange={(e) =>
                setPlatformCode(e.target.value)}
          />
        </div> */}
        {/* {loginInputs.map((v) => {
          return (
            <div key={v.name} className="loginInput mb-2">
              <Input 
                sx={{'width' : '100%'}} 
                ref={v.valueRef}
                placeholder={v.name} 
                type={v.type}
                required={v.require}
                inputProps={ariaLabel} 
              /> {v.icon}
              <input
                ref={v.valueRef}
                type={v.type}
                required={v.require}
                placeholder={v.name} />
              {v.icon}
            </div>
          )
        })} */}
        {/* <input type="submit"/> */}
        <button className='com-btn' type="submit">
          <FormattedMessage
              id={`login.login`}
          ></FormattedMessage> 
        </button>
        <div className="links">
          {/* <Link to="/">先去逛逛</Link> */}
          {
            csLoading || csPhone.map((v)=>{
              return(
                <Link onClick={()=>goCS(v.phone)}>
                  <div>{v.name}</div>
                </Link>
              )
            })
          }
          {/* <Link onClick={()=>goCS(0)}>
             <div>CSKH1</div>
          </Link>
          <Link onClick={()=>goCS(1)}>
            <div>CSKH2</div>
          </Link> */}
        </div>
      </form>
      <div className='flexBx'>
        {changLan({lang , setLang})}
        <Link to="/register" className="registerBtn">
          <h3>
              <FormattedMessage
                  id={`login.register`}
              ></FormattedMessage> 
          </h3>
        </Link>
      </div>
      
      
       
    </div>
  )
}

export default LoginForm