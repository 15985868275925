import React from 'react'
import { useNavigate } from "react-router-dom";
import CountDown from '../All/CountDown';
import { useIntl } from "react-intl";
import Swal from 'sweetalert2';

const vietnamTimezoneOffset = 7 * 60; 

const now = new Date();
const utcTime = now.getTime() + (now.getTimezoneOffset() * 60000);

const vietnamTime = new Date(utcTime + (vietnamTimezoneOffset * 60000));

const hours = vietnamTime.getHours();
const minutes = vietnamTime.getUTCMinutes();


console.log(vietnamTime , hours , minutes)

const GameCard = ({ imageSrc , cardData , getGameForward , drawAt , getListGame , gameType}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  return (
    <>
      <div className="gameCard"
        onClick={() => {
          if(cardData.game_id === 1){
            navigate(`/game/1`);
          } 
          else if(cardData.third_party_game_id !== 1 ){
            getGameForward(cardData.game_id)
            
          }
          else if (cardData.game_id === 113){
            navigate(`/game/113`);
          } 
          else if (cardData.game_id === 114){
            navigate(`/game/114`);
          } 
          else if (cardData.game_id === 107){
            navigate(`/fourBall`);
          } else if (cardData.game_id === 108){
            navigate(`/fourBallMa`);
          } else if (cardData.game_id === 109){
            navigate(`/markSix`);
          } else if (cardData.game_id === 111){
            navigate(`/markSixMacao`);
          } else if (cardData.game_id === 115 || cardData.game_id === 157 || cardData.game_id === 158){
            navigate(`/vietnamLottery/${cardData.game_id}`);
          } else if (cardData.game_id === 112){
            navigate(`/keno`);  
          } else if (cardData.game_id >= 159 || cardData.game_id <= 160){
            if ((hours >= 22) || (hours < 5) || (hours === 5 && minutes < 50)) {
              Swal.fire({
                icon: "warning",
                text: intl.formatMessage({
                  id: 'vLottery.Closed',
                }),
                showConfirmButton: true,
              });
            } else {
              navigate(`/bingo/${cardData.game_id}`);
              localStorage.setItem("bingoID", cardData.game_id)
            }
          } else if (cardData.game_id >= 161 || cardData.game_id <= 166){
            navigate(`/bingo/${cardData.game_id}`);
            localStorage.setItem("bingoID", cardData.game_id)
          } else {
            getGameForward(cardData.game_id)
           
          }
        }}>
         <img src={imageSrc}></img>
         {/* {
          cardData.type_id !== 1 &&
          <div className='des'>{cardData.name}</div>
         } */}
         {
          cardData.type_id === 1 && cardData.game_id === 115 &&
          <div className='countDown'>
            <CountDown
            nowTime={drawAt[cardData.game_id]}
            getListGame={getListGame}
            ></CountDown>
          </div>
         }
         {
          cardData.type_id === 1 && cardData.game_id === 157 &&
          <div className='countDown'>
            <CountDown
            nowTime={drawAt[cardData.game_id]}
            getListGame={getListGame}
            ></CountDown>
          </div>
         }
         {
          cardData.type_id === 1 && cardData.game_id === 158 &&
          <div className='countDown'>
            <CountDown
            nowTime={drawAt[cardData.game_id]}
            getListGame={getListGame}
            ></CountDown>
          </div>
         }

      </div>
    </>
  )
}

export default GameCard