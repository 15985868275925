import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'
import { useSelector , useDispatch , Provider} from 'react-redux';
import { FormattedMessage , useIntl } from "react-intl";
import moment from "moment";
import Page from '../../Component/All/Page'
import SearchBar from './searchBar'
import Spinner from '../Spinner/Spinner';
import GroupsIcon from '@mui/icons-material/Groups';
import ReplyIcon from '@mui/icons-material/Reply';
import { ErrorContext } from '../../Routes';


const Agents = ({setBoxSwitch}) => {
  const [perpage] = useState(10)
  const [nowPage, setNowPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [platformMemberID , setPlatformMemberID] = useState('')
  const [loading , setLoading] = useState(true)
  //redux
  const dispatch = useDispatch();
  const agentGroup = useSelector(state => state.agent.agentGroup)
  const { TimeMoment } = useContext(ErrorContext);

  const intl = useIntl();


  useEffect(() => {
  dispatch({ type: 'SAGA_FETCH_AGENTGROUP_API' , platformMemberID , setLoading , setTotalPage , perpage , nowPage});
  }, [dispatch , platformMemberID , nowPage]);

//   useEffect(() => {
//     setTotalPage(Math.ceil(betHistory.data.data.pagination.total / perpage))
//   }, [betHistory.data.status])
  
  return (
    <div className="agentsWrap bottWrap">
            <div className='listBx'>
                <div className='titleFlex'>
                    <button className='backBtn' onClick={()=>{
                        setBoxSwitch(false)
                    }}
                    ><ReplyIcon/></button>
                    
                    <div>
                        <FormattedMessage
                            id={`profile.agents`}
                        ></FormattedMessage>
                    </div>
                </div>
                <SearchBar setPlatformMemberID={setPlatformMemberID}/>
                {agentGroup.status ? 
                        agentGroup.data.response.length === 0  ?
                        <div className='notData'>
                            <FormattedMessage
                                id={`profile.noDataFound`}
                            ></FormattedMessage>
                        </div> :
                        <>
                            {
                                loading ||
                                <>
                                    <div className='selBx'>
                                        {
                                            agentGroup.data.direct_group.length === 0 ?
                                            <div>{agentGroup.data.response[0].account}</div>
                                            :
                                            agentGroup.data.direct_group.map((v)=>{
                                                return(
                                                    <div key={v.id} onClick={()=>{
                                                        setPlatformMemberID(v.account)
                                                    }}>{v.account}</div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='scrollBx'>
                                        {  
                                            <ul>
                                                {
                                                    // console.log(agentGroup)
                                                    agentGroup.data.response.map((v)=>{ 
                                                        return (
                                                            <li key={v.id}>
                                                                <div>
                                                                    <span><GroupsIcon/></span>
                                                                    <i onClick={()=>{
                                                                        setPlatformMemberID(v.account)
                                                                    }}>{v.account}</i>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        <FormattedMessage
                                                                            id={`agent.balance`}
                                                                        ></FormattedMessage>
                                                                    </span>
                                                                    {v.balance}
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                    有效流水 :
                                                                    </span>
                                                                    {v.valid_bet_amount}
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        <FormattedMessage
                                                                            id={`agent.lastLogin`}
                                                                        ></FormattedMessage>
                                                                    </span>
                                                                    {
                                                                        TimeMoment(v.login_at)
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        <FormattedMessage
                                                                            id={`agent.registrationDate`}
                                                                        ></FormattedMessage>
                                                                    </span>
                                                                    {
                                                                        TimeMoment(v.create_at)
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        }
                                    </div>
                                   
                                </>
                            }
                        </>   
                    : <Spinner/> 
                }
                {totalPage > 1 &&
                <Page
                    totalPage={totalPage}
                    setNowPage={setNowPage}
                    nowPage={nowPage}
                />}
            </div>
    </div>
  )
}

export default Agents